// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gebrauchstauglichkeitsakte-tsx": () => import("./../../../src/pages/Gebrauchstauglichkeitsakte.tsx" /* webpackChunkName: "component---src-pages-gebrauchstauglichkeitsakte-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/Impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/Kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

